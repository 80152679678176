// Import all of Bootstrap's CSS

$btn-border-radius-lg: 1.8rem;
//$font-family-sans-serif: "Comic Sans MS", "Comic Sans", cursive;
$font-family-sans-serif: "Gupter", "Helvetica", cursive;
$gray-100: #efeded;
$gray-800: #002e6d;
$gray-600: #adb5bd;
$orange: #f18d1e;
$yellow: #f18d1e;
$blue: #0081c3;

.content-container {
    min-height: 630px;
}

@import "bootstrap/scss/bootstrap";

// html,
// body,
// #app,
// #app>div {
//     height: 100%
// }

.bg-dark {
    background-color: #002e6d !important;
}

.btn-primary {
    color: #fff;
}

.btn-lg {
    text-transform: uppercase;
}

// body {
//     background-color: #ececec;
// }

h4 {
    text-transform: uppercase;
}

a,
a:focus,
button,
button:focus {
    outline: none;
}

.clicker {
    cursor: pointer;
}


.forbidden {
    opacity: 0.5;
    background-color: dimgrey;
    color: linen;
    cursor: not-allowed;
}

.full-square {
    width: 100%;
    border-radius: 0rem !important;
}

.accordion-button {
    width: 100%;
    font-size: 1rem !important;
    align-items: center;
    border: 0;
    border-radius: 0;
    display: flex;
    overflow-anchor: none;
    position: relative;
    text-align: left;
}

.brokerVideo {
    width: 100%;
}

.form-label {
    font-weight: 600;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
}

/* .videoWrapper iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
} */


.desktop-menu {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    font-size: 1rem !important;
}

.broker-logo {
    position: fixed;
    top: 60px;
    left: 20px;
    width: 180px;
    /*height: 4.12rem;*/
}

.broker-logo-mobile {
    height: 4.12rem;
}